import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import './MeasurabilityPage.css';
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend
);

function MeasurabilityPage() {
    // State to hold the fetched data
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'domain', direction: 'ascending' });
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');

                if (!token) {
                    navigate('/login');
                    return;
                }

                const response = await fetch('/api/kpis/kpis/', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const result = await response.json();
                setData(result);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    // sorting function for table. (has to be above conditional imports for some fucking reason)
    const sortedData = React.useMemo(() => {
        if (!data || !data.domain_metrics) return [];
        let sortableItems = [...data.domain_metrics];

        // Remove the check for sortConfig !== null
        sortableItems.sort((a, b) => {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];
            if (typeof aValue === 'string') {
                aValue = aValue.toLowerCase();
                bValue = bValue.toLowerCase();
            }
            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        return sortableItems;
    }, [data, sortConfig]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    // Prepare data for charts
    const pageVisitsDates = Object.keys(data.page_visits_daily);
    const pageVisitsCounts = Object.values(data.page_visits_daily);

    const medianQueriesDates = Object.keys(data.median_user_search_queries_daily);
    const medianQueriesValues = Object.values(data.median_user_search_queries_daily);

    const threeWordsLinksDates = Object.keys(data.three_words_link_created_daily);
    const threeWordsLinksCounts = Object.values(data.three_words_link_created_daily);

    // Chart Data
    const pageVisitsChartData = {
        labels: pageVisitsDates,
        datasets: [
            {
                label: 'Daily Page Visits',
                data: pageVisitsCounts,
                fill: false,
                backgroundColor: "#4DA1BF",
                borderColor: "#4DA1BF",
            },
        ],
    };

    const medianQueriesChartData = {
        labels: medianQueriesDates,
        datasets: [
            {
                label: 'Median User Search Queries Daily',
                data: medianQueriesValues,
                fill: false,
                backgroundColor: '#4CBFA4',
                borderColor: '#4CBFA4',
            },
        ],
    };

    const threeWordsLinksChartData = {
        labels: threeWordsLinksDates,
        datasets: [
            {
                label: 'Three Words Links Created Daily',
                data: threeWordsLinksCounts,
                fill: false,
                backgroundColor: '#BF4D67',
                borderColor: '#BF4D67',
            },
        ],
    };

    // Base options object
    const baseOptions = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                title: {
                    display: true,
                    text: '', // Placeholder text
                    color: '', // Placeholder color
                    font: {
                        size: 18,
                    },
                },
                ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                },
            },
        },
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
        },
    };

    const pageVisitsChartOptions = {
        ...baseOptions,
        scales: {
            ...baseOptions.scales,
            y: {
                ...baseOptions.scales.y,
                title: {
                    ...baseOptions.scales.y.title,
                    text: 'Daily Page Visits',
                    color: '#4DA1BF',
                },
            },
        },
    };

    const medianQueriesChartOptions = {
        ...baseOptions,
        scales: {
            ...baseOptions.scales,
            y: {
                ...baseOptions.scales.y,
                title: {
                    ...baseOptions.scales.y.title,
                    text: 'Median User Search Queries',
                    color: '#4CBFA4',
                },
            },
        },
    };

    const threeWordsLinksChartOptions = {
        ...baseOptions,
        scales: {
            ...baseOptions.scales,
            y: {
                ...baseOptions.scales.y,
                title: {
                    ...baseOptions.scales.y.title,
                    text: 'Three Words Links Created',
                    color: '#BF4D67',
                },
            },
        },
    };

    // Sorting for table (unchanged)
    const requestSort = (key) => {
        let direction = 'ascending';
        if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === 'ascending'
        ) {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return (
        <div className='row g-0 measurability-container'>
            <div className='col-md-2'></div>
            <div className='col-md-8'>
                <div className='row g-0'>
                    <h1>Dashboard</h1>
                </div>
                <div className='row g-0'>
                    <div className='col-md-6'>
                        <div className='dashboard-item'>
                            <h2>Page Visits</h2>
                            <h3>Total: {data.page_visits}</h3>
                            <Line data={pageVisitsChartData} options={pageVisitsChartOptions} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='dashboard-item'>
                            <h2>Median User Search Queries</h2>
                            <h3>All Time: {data.median_user_search_queries}</h3>
                            <Line data={medianQueriesChartData} options={medianQueriesChartOptions} />
                        </div>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-md-6'>
                        <div className='dashboard-item'>
                            <h2>Three Word Links</h2>
                            <h3>Total: {data.three_words_links}</h3>
                            <Line data={threeWordsLinksChartData} options={threeWordsLinksChartOptions} />
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className='row g-0'>
                    <h2>Domain Table</h2>
                    <table>
                        <thead>
                            <tr>
                                <th onClick={() => requestSort('domain')}>
                                    Domain {sortConfig.key === 'domain' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                                </th>
                                <th onClick={() => requestSort('num_entries')}>
                                    Number of Three Words {sortConfig.key === 'num_entries' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                                </th>
                                <th onClick={() => requestSort('num_searches')}>
                                    Number of Searches {sortConfig.key === 'num_searches' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : null}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((item) => (
                                <tr key={item.domain}>
                                    <td>{item.domain}</td>
                                    <td>{item.num_entries}</td>
                                    <td>{item.num_searches}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='col-md-2'></div>
        </div>
    );
}

export default MeasurabilityPage;
